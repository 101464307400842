<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma" type="primary" @click="showAddModel">新增动态分类</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="goodPhoto" align="center" label="图标" width="120px">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.typeIcon"
                            style="width: 50px; height: 50px;margin-bottom:-10px" :src="scope.row.typeIcon">
                        </el-image>
                        <p v-else style="width: 50px; height: 50px;line-height: 50px;font-size:12px;margin:0 auto;background: #eee;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="dictLabel" align="center" label="大分类" show-overflow-tooltip />
                <el-table-column prop="typeName" align="center" label="小分类" show-overflow-tooltip />
                <el-table-column prop="type" align="center" label="内容形式" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.type==1?'单篇文章':"文章列表"}}
                    </template>
                </el-table-column>
                <el-table-column prop="statusTitle" align="center" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="disableFun(scope.row,$event)" :active-value="0" :inactive-value="1">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateTypeModel(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="typeModelTitle" :visible.sync="typeShowModel" 
            :destroy-on-close="true" width="380px" center @close="closeFun()">
            <el-form :model="typeFormData" :rules="typeRules" ref="typeFormData" label-width="100px">
                <el-form-item label="图标" prop="typeIcon">
                    <el-upload class="iconUpload" :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                        :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="typeIcon">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="w200 h100p d-flex align-items-center">
                        <p>最多只能上传1张，建议上传100x100，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-form-item label="内容形式" prop="type">
                    <el-radio-group v-model="typeFormData.type">
                        <el-radio-button :label="1">单篇文章</el-radio-button>
                        <el-radio-button :label="0">文章列表</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="大分类" prop="contentType">
                    <el-select class="w200" v-model="typeFormData.contentType" placeholder="请选择">
                        <el-option v-for="(name,id) in contentType" :key="id" :label="name" :value="id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="小分类名称" prop="typeName">
                    <el-input class="w200" v-model="typeFormData.typeName" placeholder="请输入小分类名称"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="typeShowModel = false">取 消</el-button>
                <el-button type="primary" @click="typeSubmit" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getByDictType, getContentTypeList, setContentType } from "@/api/parkManagement";
export default {
    name: "groupingTable",
    components: {
        commonTable
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            contentType: {},
            parkId: 0,
            //编辑新增配置
            memberLevel: {},//会员类型
            memberJob: {},//会员职务
            typeModelTitle: "",
            typeShowModel: false,
            typeIcon:[],
            typeFormData: {
                type:1,
                typeIcon:"",
                contentType: "",
                typeName: "",
            },
            typeRules: {
                // typeIcon: {
                //     required: true,
                //     message: "请上传图标",
                // },
                typeName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入类型名称",
                },
                contentType: {
                    required: true,
                    trigger: "chnage",
                    message: "请选择类型",
                },
            },
            formType: "",
            updateId: 0,
            statusData: { 0: "正常", 1: "禁用" },
            loadingBtn:false,
        };
    },
    computed: {
        uploadDisabled() {
            return this.typeFormData.typeIcon !== "";
        },
        watchCheckedPark() {
            return this.$store.state.checkedPark
        }
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.getList();
        },
    },
    filters: {

    },
    created() {
        this.parkId = JSON.parse(sessionStorage.getItem('checkedPark')).parkId;
        this.getByDictType()
        this.getList();
    },
    methods: {
        closeFun(){
            this.typeFormData.typeIcon = "";
            this.typeIcon=[];
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            //-console.log(file, fileList);
            this.typeFormData.typeIcon = "";
            this.typeIcon=[];
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {
            
        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.typeFormData.typeIcon = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**@method 获取字典数据 */
        getByDictType() {
            return getByDictType({ dictType: "content_type", status: 0 }).then(res => {
                if (res.code === 200) {
                    let contentType = {};
                    for (let row of res.data.sysDictDataList) {
                        contentType[row.dictValue] = row.dictLabel;
                    }
                    this.contentType = contentType;
                }
            })
        },
        /**@method 搜索 */
        searchFun() { this.getList() },
        /**@method 获取列表 */
        async getList() {
            let params = {
                parkId: this.parkId,
                typeName: this.searchData.name
            };
            try {
                this.loading = true;
                let res = await getContentTypeList(params)
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data) {
                    row.statusTitle = this.statusData[row.status]
                    tableData.push(row);
                }
                this.tableData = tableData;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        showAddModel(row) {
            this.typeShowModel = true;
            this.typeModelTitle = "新增动态类型"
            this.formType = "add";
            this.updateId = 0;
            this.typeFormData = {
                type:1,
                typeIcon:"",
                contentType:"",
                typeName: "",
            }
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateTypeModel(row) {
            this.typeShowModel = true;
            this.typeModelTitle = "编辑动态类型"
            this.formType = "update";
            this.updateId = row.typeId;
            this.typeFormData = {
                typeIcon: row.typeIcon?row.typeIcon:"",
                type:row.type,
                contentType: row.contentType,
                typeName: row.typeName,
            };
            if (row.typeIcon) {
                this.typeIcon = [{ url: row.typeIcon }]
            }
        },
        disableFun(row, status) {
            let parmas = {
                parkId: this.parkId,
                typeName:row.typeName,
                typeId: row.typeId,
                status: status,
                contentType:row.contentType
            };
            setContentType(parmas).then(res => {
                if (res.code === 200) {
                    this.getList();
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        typeSubmit() {
            //修改添加会员等级
            this.$refs.typeFormData.validate((valid) => {
                if (valid) {
                    let parmas = {
                        ...this.typeFormData,
                        parkId: this.parkId
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        parmas.typeId = this.updateId
                        message = "编辑成功";
                    }
                    this.loadingBtn=true;
                    setContentType(parmas).then(res => {
                        this.loadingBtn=false;
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.typeShowModel = false;
                            this.getList();
                        } else {
                            this.$message.error(err.message)
                        }
                    }).catch(err => {
                        this.loadingBtn=false;
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>